// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDzMfp58OKzMI7ziOxfaguqIhiuhTP0Ztc",
    authDomain: "medclub-6e133.firebaseapp.com",
    projectId: "medclub-6e133",
    storageBucket: "medclub-6e133.appspot.com",
    messagingSenderId: "158262320645",
    appId: "1:158262320645:web:6a3638552bd0ea7357866c",
};


// Initialize Firebase
export const firebase_app = initializeApp(firebaseConfig);
export const firebase_db = getFirestore(firebase_app);
