import router from "./../router";
import Vue from "vue";
import axios from "axios";

const env = 'prod'

const environments = {
    local: {
        baseUrl: "http://127.0.0.1:8000/v1",
    },
    dev: {
        baseUrl: "https://apihom.medclub.com.br/dev/v1",
        basePath: '/dev/',
    },
    homolog: {
        baseUrl: "https://apihom.medclub.com.br/v1",
        basePath: '/hom/',
    },
    prod: {
        baseUrl: "https://api.medclub.com.br/v1",
    },
};


const videoCallIframeByEnv = {
    prod: "https://meeting.medclub.com.br/widget.js?key=medclubapp&channel=1",
	homolog: 'https://meeting-hom.medclub.com.br/widget.js?key=medclubapp&channel=1',
	local: 'https://meeting-hom.medclub.com.br/widget.js?key=medclubapp&channel=1'
};

const video_call_iframe_url = videoCallIframeByEnv[env]


let checkConnection = true;

const memed = {
    homolog: {
        DOMINIO_SCRIPT_MEMED: "sandbox.memed.com.br",
        DOMINIO_API_MEMED: "sandbox.api.memed.com.br",
    },
    prod: {
        DOMINIO_SCRIPT_MEMED: "memed.com.br",
        DOMINIO_API_MEMED: "api.memed.com.br",
    },
};

const nexoData = {
    homolog: {
        DOMINIO_API_NEXODATA: "emr-homolog.nexodata.com.br/api",
        DOMINIO_SCRIPT_NEXODATA: "staging-embedded.nexodata.com.br",
        AUTH_TOKEN: "bWVkY2x1Yjo2dDRmTkJhUUpjQ2VQMQ==",
    },
    local: {
        DOMINIO_API_NEXODATA: "emr-homolog.nexodata.com.br/api",
        DOMINIO_SCRIPT_NEXODATA: "staging-embedded.nexodata.com.br",
        AUTH_TOKEN: "bWVkY2x1Yjo2dDRmTkJhUUpjQ2VQMQ==",
    },
    prod: {
        DOMINIO_API_NEXODATA: "emr.nexodata.com.br/api",
        DOMINIO_SCRIPT_NEXODATA: "embedded.nexodata.com.br",
        AUTH_TOKEN: "bWVkY2x1YjpseGNJN1dzYnRwWUI=",
    },
};

const novaAPI = {
    homolog: {
        BASE_URL: "https://med-api.medclub.com.br",
        applicationId: "$2b$1232ou",
        applicationToken: "$2b$10$KP0C6OX16t8YnOP8B232ou",
        username: "newsCreator",
        password: "newsCreator",
    },
    prod: {
        BASE_URL: "https://med-api.medclub.com.br",
        applicationId: "$2b$1232ou",
        applicationToken: "$2b$10$KP0C6OX16t8YnOP8B232ou",
        username: "newsCreator",
        password: "newsCreator",
    },
};

export default {
    env,
    memed: memed[env],
	video_call_iframe_url,
    nexoData: nexoData[env],
    novaAPI: novaAPI[env],
    baseUrl: environments[env].baseUrl,
    basePath: environments[env].basePath,
    showEnv: process.env.NODE_ENV === "development" || env != "prod",
    clientId: "DAK60ESDtVEcMGaryXlJpwpwuvsLqlmwr0Yp5tsI",
    clientSecret:
        "MDC4Ga2Xcq1BqFWLRs0Mhd25borYwE1kEXhbOxyKKvqMNkrPq6teO8trRroOssxL4ZWGHQ3I71LQrQSGoZBanRYxZHwYzQVMmKZMKN98tWPwvRtOKpWIs9HCT4C3PwLU",
    errorHandler: (err) => {
        if (err instanceof axios.Cancel) return;
        if (err.response) {
            checkConnection = true;
            if ([401].includes(err.response.status)) {
                let usuario = JSON.parse(localStorage.getItem("usuario"));
                if (usuario) {
                    if (usuario.sessionValid) {
                        usuario.sessionValid = false;
                        localStorage.setItem(
                            "usuario",
                            JSON.stringify(usuario)
                        );
                        router.go(0);
                    }
                }
            } else if ([403].includes(err.response.status)) {
                Vue.$toast.error("Você não tem permissão", { duration: 3000 });
            } else if (err.response.status == 500) {
                Vue.$toast.error(
                    "Erro interno no servidor, tente novamente em instantes",
                    { duration: 4000 }
                );
            }
            return err.response;
        } else if (checkConnection) {
            checkConnection = false;
            Vue.$toast.error("Sem conexão com o servidor", { duration: 3000 });
            return false;
        }
    },
    getMediaUrl() {
        return "";
    },
    paginator_perPage: 25,
    menuForcedOrder: [
        "comunicacao-interna",
        "agendamentos-parent",
        "caixas-parent",
        "procedimentos-parent",
        "paciente",
    ],
};
