<!-- eslint-disable vue/valid-template-root -->
<template>
    <fragment>
        <slot />
    </fragment>
</template>

<script>
import { firebase_db } from "../firebase"; // Adjust the path to your Firebase setup
import { collection, doc, deleteDoc, onSnapshot } from "firebase/firestore";
import { COLLECTION_GESTOR_NOTIFICACOES } from './utils/fb_utils'
import { Fragment } from 'vue-fragment'
import configs from "../middleware/configs";

export default {
    name: "NotificationsProvider",
    data() {
        return {
            messages: [],
            unreadCount: 0,
            firstLoading: true
        };
    },
    created() {
        this.fetchNotifications();
    },
    components: { Fragment },
    methods: {
        fetchNotifications() {
            const unsubscribe = onSnapshot(
                collection(
                    firebase_db,
                    COLLECTION_GESTOR_NOTIFICACOES,
                ),
                (snapshot) => {
                    if (!this.firstLoading) {
                        const unreadMessages = snapshot.docs.filter(doc => {
                            const docWasAlreadyRed = this.messages.some(msg => msg.id === doc.id)
                            return !docWasAlreadyRed
                        })
                        const usuario = JSON.parse(localStorage.getItem("usuario"));

                        unreadMessages.forEach(msg => {
                            if (!msg.data()?.groups_allowed) {
                                if (msg.data()?.apenas_para_quem_executou && Number(msg.data().cd_usuario) === usuario.id){
                                    // Este IF é para enviar uma notificação para o usuário que executou alguma ação que chamou a função do firestore no back.
                                    const msg_homolog = msg.data().homolog
                                    const is_homolog = configs.env === 'homolog'
                                    if (is_homolog === msg_homolog) {
                                        this.$toast.open({
                                            message: msg.data().message,
                                            type: msg.data().type || 'warning',
                                            position: msg.data().position || 'top-left',
                                            dismissible: msg.data().dismissible || true,
                                            duration: msg.data().duration || 0,
                                            onClick: () => {
                                                if (msg.data().redirect_url) this.$router.push(msg.data().redirect_url)
                                            },
                                        })
                                        const audio = new Audio(require('../assets/audio/notification.ogg'))
                                        audio.play()
                                    }
                                }
                            }else{
                                // array_com_ids_grupos_permitidos <- array_com_grupos
                                const groups_allowed_to_receive_notification = msg.data().groups_allowed
                                const isAllowed = usuario && usuario.grupos.find(g => groups_allowed_to_receive_notification.includes(g.id))
                                const msg_homolog = msg.data().homolog
                                const is_homolog = configs.env === 'homolog'
                                if (isAllowed && is_homolog === msg_homolog) {
                                    this.$toast.open({
                                        message: msg.data().message,
                                        type: msg.data().type || 'warning',
                                        position: msg.data().position || 'top-left',
                                        dismissible: msg.data().dismissible || true,
                                        duration: msg.data().duration || 0,
                                        onClick: () => {
                                            if (msg.data().redirect_url) this.$router.push(msg.data().redirect_url)
                                        },
                                    })
                                    const audio = new Audio(require('../assets/audio/notification.ogg'))
                                    audio.play()
                                }
                            }

                        })
                    }
                    this.firstLoading = false
                    const messages = snapshot.docs.map((doc) => ({
                        message: doc.data().message,
                        id: doc.id,
                    }));
                    this.messages = messages;
                    this.unreadCount = messages.length ? this.unreadCount + 1 : 0;
                }
            );
            this.$once("hook:beforeDestroy", unsubscribe);
        },
        resetUnreadCount() {
            this.unreadCount = 0;
        },
        async markAllAsRead() {
            await Promise.all(
                this.messages.map(async (msg) => {
                    await deleteDoc(
                        doc(
                            firebase_db,
                            COLLECTION_GESTOR_NOTIFICACOES,
                            msg.id
                        )
                    );
                })
            );
            this.messages = [];
        },
        async markOneMessageAsRead(id) {
            await deleteDoc(
                doc(
                    firebase_db,
                    COLLECTION_GESTOR_NOTIFICACOES,
                    id
                )
            );

            this.messages = this.messages.filter((msg) => msg.id !== id);
        },
    },
    provide() {
        const exposedProps = ['messages', 'unreadCount']; // <--- notice this list of properties to be exposed as proxy for realtime changes
        const providedState = new Proxy(this.$data, {
            get(target, prop) {
                return exposedProps.includes(prop) ? Reflect.get(...arguments) : undefined;
            },
            ownKeys(target) {
                return Reflect.ownKeys(target).filter(key => exposedProps.includes(key));
            }
        });
        return {
            providedState,
            resetUnreadCount: this.resetUnreadCount,
            markAllAsRead: this.markAllAsRead,
            markOneMessageAsRead: this.markOneMessageAsRead
        }
    },
};
</script>